.about-info {
  font-family: "Times New Roman", Times, serif;
  display: flex;
  margin: 40px;
  /* width: 90%; */
  height: 40%;
}
.about-info-image {
  border-radius: 10px;
  height: 500px;
  width: 50%;
  margin-right: 10px;
  margin-left: 10px;
  /* background-color: #f5f5f5; */
}
.navbar {
  max-width: none !important;
}

.home-header-image {
  background-image: url("./assets/news/home-bottom-shape.png");
  background-size: cover;
  background-position: center;
  min-height: 300px;
  width: "100%";
}

.bandeau-image1 {
  background-image: url("./assets/news/banner-bg.jpg");
  background-size: cover;
  background-position: center;
  min-height: 200px;
  width: "100%";
}

.bandeau-image {
  /* background-image: url("./assets/news/banner-bg.jpg"); */
  background-size: cover;
  background-position: center;
  min-height: 400px;
  width: "100%";
}

.home-image {
}
.footer {
  font-size: 16px;
  height: 30px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: 0;
}

.footer-links-item {
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-top: 50;
  text-align: justify;
  font-size: 16px;
}

.about-image {
  flex: 1;
  width: 100%;
  /* height: 100%; */
  border-radius: 10%;
}
.about-desciption {
  flex: 8;
  width: 80%;
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3%;
  border-radius: 10px;
  margin-left: 10px;
}
.about-description-title {
  color: #888687;
  font-weight: bolder;
  text-align: right;
}
.about-description-title-sous {
  font-size: 28px;
  font-weight: bolder;
}
@media screen and (max-width: 1000px) {
  .about-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-image {
    width: 100%;
  }
  .about-info-image {
    width: 70%;
  }
}
@media screen and (max-width: 500px) {
  .about-image {
    width: 100%;
  }
  .about-desciption {
    width: 100%;
  }
}

@media all and (max-width: 890px) {
  .password-form {
    margin: 0 !important;
  }
}

@media all and (min-width: 891px) {
  .password-form {
    margin: 54px 16px 16px 16px !important;
  }
}

@media all and (max-width: 890px) {
  .password-form {
    display: none;
  }
}

@media all and (max-width: 888px) {
  .login-form,
  .home-image {
    margin: 0 !important;
  }
}

@media all and (min-width: 889px) {
  .login-form,
  .home-image {
    margin: 54px 16px 16px 16px !important;
  }
}

@media all and (max-width: 888px) {
  .login-form,
  .home-image {
    display: none;
  }
}

/* @media all and (max-width: 765px) {
  .login-form {
    display: none;
  }
} */

@media all and (max-width: 888px) {
  .about-image {
    margin: 0 !important;
  }
}

@media all and (min-width: 889px) {
  .about-image {
    margin: 54px 16px 16px 32px !important;
  }
}

@media all and (max-width: 888px) {
  .about-image {
    display: none;
  }
}
