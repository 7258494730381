@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: #301274;
  background: -webkit-linear-gradient(0deg, #301274 30%, #0057a9 40%, #089cd0 50%);
  background: -moz-linear-gradient(0deg, #301274 30%, #0057a9 40%, #089cd0 50%);
  background: linear-gradient(0deg, #301274 30%, #0057a9 40%, #089cd0 50%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Poppins", monospace;
}
